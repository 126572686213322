import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Suspense } from 'react';
import { lazily } from 'react-lazily';
const { Menu }= lazily(() => import('./components'));
const { VideMaison }= lazily(() => import('./container'));
const { Services }= lazily(() => import('./container'));
const { Process }= lazily(() => import('./container'));
const { Testimonials }= lazily(() => import('./container'));
const { AboutUs }= lazily(() => import('./container'));
const { Contact }= lazily(() => import('./container'));
const { Footer }= lazily(() => import('./container'));


const App = () => (

    <div className="container" id="home">
        <Suspense>
            <Menu />
            <VideMaison />
            <Services />
            <Process />
            <Testimonials />
            <AboutUs />
            <Contact />
            <Footer />
        </Suspense>
    </div>

);

export default App;